/*
----------- STRUCTURE -----------
3) Header
  a- Banner
  b- Booking form
4) Main
  a- Map
5) Footer
*/

/* ---------------------------------------------- */
/* 2) Navbar */

/* ---------------------------------------------- */
/* 3) Header */

/* a- Banner */
body .banner-container {
  background: #000 url("../../../images/banner.jpg") center center no-repeat;
  background-size: cover;
  height: 45vh;
  min-height: 350px;
}

.banner-container h2 {
  width: 70%;
  min-width: 300px;
  text-align: center;
  color: white;
  margin-bottom: -70px;
}

/* b- Booking form */
body .booking-container {
  bottom: initial;
  top: 22vh;
}

/* ---------------------------------------------- */
/* 3) Main */

/* a- Map */
.map-container {
  position: relative;
  border-top: 8px solid #e6b664;
  width: 100%;
  height: 90vh;
}

.map-marker {
  background-color: #fed66a;
  transform: translate(-50%, -50%);
  cursor: "pointer";
  width: 100px;
  padding: 10px;
  text-align: center;
  position: absolute;
  border-radius: 8px;
  font-weight: bold;
  top: -20px;

  &::before {
    content: "";
    position: absolute;
    top: 32px;
    left: 40px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 10px solid #fed66a;
  }
}

#map-canvas {
  width: 100%;
  height: 90vh;
  margin: 0 auto;
  padding: 0px;
}

#panel {
  z-index: 5;
  background-color: #fff;
  margin: 4vh 10vw;
  padding: 20px 30px;
  position: absolute;
  width: 80vw;
  border: 1px solid #999;
}

.op-0 {
  opacity: 0;
}

/* ---------------------------------------------- */
/* 5) Footer */
footer {
  background: #000 url("../../../images/rooms-bg.jpg") center center no-repeat;
  background-size: cover;
  color: #fff;
}

.banner-block {
  display: none;
}

/* MOBILE */

@media only screen and (max-width: 900px) {
  .banner-container h2 {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 0;
  }

  .banner-block {
    display: block;
  }

  #panel {
    margin: 0;
    padding: 20px 30px;
    position: relative;
    width: 100%;
    border: none;
  }
}
