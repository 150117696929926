.ReactModal__Overlay {
  z-index: 99999999 !important;
}

.image-thumb-container {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2231267507002801) 0%,
      rgba(0, 0, 0, 0.15870098039215685) 35%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  span {
    position: absolute;
    top: 4px;
    left: 10px;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    z-index: 1;
  }
}

.image-thumb {
  width: 100%;
  cursor: pointer;
}
