/*
----------- STRUCTURE -----------
3) Header
  a- Banner
  b- Booking form
4) Main
  a- Map
5) Footer
*/

/* General */
.btn-margin {
  margin: 50px 0;
  cursor: pointer;
  -webkit-appearance: none !important;
}

/* ---------------------------------------------- */
/* 2) Navbar */

/* ---------------------------------------------- */
/* 3) Header */

/* a- Banner */
body .banner-container {
  background: #000 url("../../../images/banner.jpg") center center no-repeat;
  background-size: cover;
  height: 45vh;
  min-height: 350px;
}

.banner-block {
  display: none;
}

/* b- Booking form */
body .booking-container {
  bottom: initial;
  top: 22vh;
}

/* ---------------------------------------------- */
/* 3) Main */
/* a- Intro */
.section-intro {
  background-color: #fff;
}

.section-title {
  padding: 3rem 0;
  position: relative;
}

.section-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.3rem;
  margin: 0 auto;
  width: 150px;
  height: 1px;
  background: #e6b664;
}

.section-subtitle {
  font-size: 1.2rem;
}

/* b- Services block */

/* c- Services */
.services-container-servicios {
  width: 100%;
  flex-direction: column;
}

.desc-content {
  padding-top: 2vw;
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.services-img {
  text-align: right;
}

.services-img img {
  max-width: 80%;
}

.item {
  text-align: center;
}

.services-container-servicios [class^="services-"]:before,
.services-container-servicios [class*=" services-"]:before,
.services-container-servicios [class^="services-"]:after,
.services-container-servicios [class*=" services-"]:after {
  font-size: 5rem;
}

.service-title p {
  font-size: 1.5rem;
}

.leyends strong,
.service-title p span {
  font-weight: bold;
  color: #5bbdd3;
}

.leyends {
  text-align: center;
  color: rgb(110, 110, 124);
  padding-top: 20px;
}

/* MOBILE */

@media only screen and (max-width: 900px) {
  .banner-block {
    display: block;
  }

  .section-subtitle {
    text-align: center;
  }

  .services-img img {
    max-width: 100%;
  }
}
