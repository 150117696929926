@import url("https://fonts.googleapis.com/css?family=Asap:400,400i,500,500i,600,700");
/*
----------- STRUCTURE -----------
1) General
2) Navbar
3) Header
  a- Banner
  b- Booking form
5) Footer
*/

/* ---------------------------------------------- */
/* 1) General */
body {
  font-family: "Asap", sans-serif;
}

a,
input,
select,
option,
body button,
textarea {
  border-radius: 0 !important;
}

a:hover {
  text-decoration: none;
}

section,
.footer-content {
  padding: 5rem 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#lg-share,
.lg-download {
  display: none;
}

/* ---------------------------------------------- */
/* 2) Navbar */
.logo {
  width: 12rem;
  margin-right: 2rem;
}

.navbar-custom {
  padding: 0.6rem 9rem 1rem 9rem;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.202),
    0 4px 8px 3px rgba(60, 64, 67, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30000;
}

.navbar-nav a {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 500;

  &.active {
    color: rgba(0, 0, 0, 0.9);
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav a {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.collapse-custom {
  justify-content: flex-end;
}

.dropdown-custom {
  background-color: #fff;
}

.dropdown-item {
  background-color: #fff;
  color: #000;
}

/* ---------------------------------------------- */
/* 3) Header */

/* a- Banner */
.banner-container {
  background: #000 url("../../../images/banner.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  min-height: 500px;
}

.banner-block {
  color: #fff;
  text-align: center;
}

.banner-title {
  font-size: 6rem;
  font-weight: 700;
}

.banner-subtitle {
  font-size: 1.5rem;
  text-shadow: -2px 0px 2px rgba(0, 0, 0, 0.2);
}

/* b- Booking form */
.booking-container {
  background: #fff;
  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: absolute;
  bottom: 15%;
  left: 9rem;
  right: 9rem;
  -webkit-box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.202),
    0 4px 8px 3px rgba(60, 64, 67, 0.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.202),
    0 4px 8px 3px rgba(60, 64, 67, 0.1);
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.202),
    0 4px 8px 3px rgba(60, 64, 67, 0.1);
}

.booking-container > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-input-group {
  flex: 3;
}

.small-input-group {
  flex: 1;
}

.medium-input-group {
  flex: 2;
}

.booking-input-title {
  text-align: center;
  margin-bottom: 5px;
}

.booking-input {
  margin: 0 4px;
}

.btn-custom {
  color: #fff;
  background: #e6b664;
}

.btn-custom:hover {
  cursor: pointer;
}

/* ---------------------------------------------- */
/* 5) Footer */
footer {
  background: #000 url("../../../images/rooms-bg.jpg") center center no-repeat;
  background-size: cover;
  color: #fff;
}

.footer-content {
  flex-direction: row;
}

.logo-container {
  flex: 1;
  min-width: 100px;
  text-align: left;
}

.data-container {
  flex: 7;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.data-container span {
  margin: 0 14px;
}

.footer-logo {
  width: 10rem;
}

.social-container {
  flex: 1;
  min-width: 100px;
}

.social-container span {
  display: none;
}

.social-container i {
  margin: 5px;
}

.social-container [class^="services-"]:before,
.social-container [class*=" services-"]:before,
.social-container [class^="services-"]:after,
.social-container [class*=" services-"]:after {
  font-size: 2rem;
  color: #fff;
}

.data-container i {
  margin-right: 10px;
}

.data-container span:last-child i {
  position: relative;
  top: 2px;
}

.data-container [class^="services-"]:before,
.data-container [class*=" services-"]:before,
.data-container [class^="services-"]:after,
.data-container [class*=" services-"]:after {
  font-size: 1.2rem;
  color: #fff;
}

/* MOBILE */

@media only screen and (max-width: 900px) {
  body {
    padding-top: 68px;
  }

  .navbar-custom {
    padding: 0.6rem 1rem 1rem 1rem;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  .logo {
    width: 10rem;
  }

  .data-container {
    flex-direction: column;
  }

  .banner-title {
    font-size: 24px;
  }

  .banner-subtitle {
    font-size: 18px;
  }

  .booking-container {
    display: none;
  }

  section,
  .footer-content {
    padding: 30px 20px;
  }

  .section-title::after {
    width: 105px !important;
  }

  .footer-content {
    flex-direction: column;
  }

  .data-container span {
    margin: 0;
    font-size: 14px;
  }

  .logo-container {
    padding-bottom: 15px;
  }

  .social-container {
    min-width: auto;
  }

  .h1,
  h1 {
    font-size: 28px;
    text-align: center;
  }
}
