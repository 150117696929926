/*
----------- STRUCTURE -----------
3) Header
  a- Banner
  b- Booking form
4) Main
  a- Intro
  b- Contact form
  c- FAQ
5) Footer
*/

/* ---------------------------------------------- */
/* 2) Navbar */

/* ---------------------------------------------- */
/* 3) Header */

/* a- Banner */
body .banner-container {
  background: #000 url("../../../images/banner.jpg") center center no-repeat;
  background-size: cover;
  height: 45vh;
  min-height: 350px;
}

/* b- Booking form */
body .booking-container {
  bottom: initial;
  top: 22vh;
}

a {
  color: #212529;
}

a:hover {
  color: #ca8b1e;
}

/* ---------------------------------------------- */
/* 3) Main */

/* a- Intro */
.section-intro {
  background-color: #fff;
}

.section-title {
  padding: 3rem 0;
  position: relative;
  text-align: center;
  max-width: 800px;
}

.section-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.3rem;
  margin: 0 auto;
  width: 150px;
  height: 1px;
  background: #e6b664;
}

.section-subtitle {
  font-size: 1.2rem;
}

.section-description {
  color: #5f5e5e;
  text-align: center;
  padding: 2rem 0 0 0;
  width: 70%;
}

/* b- Contact form */
.row {
  width: 100%;
}

#contact-form {
  width: 100%;

  label {
    display: block;

    > * {
      margin-top: 5px;
    }
  }
}

#contact-message {
  min-height: 100px;
}

@media only screen and (max-width: 900px) {
  .banner-block {
    display: block;
  }

  .section-form {
    padding-top: 0;
  }

  .section-form .row .col-md-6:first-child {
    padding-bottom: 40px;
  }
}
