/*
----------- STRUCTURE -----------
3) Header
  a- Banner
  b- Booking form
4) Main
  a- Intro
  b- Rooms
5) Footer
*/

/* ---------------------------------------------- */
/* 2) Navbar */

/* ---------------------------------------------- */
/* 3) Header */

/* a- Banner */
body .banner-container {
  background: #000 url("../../../images/banner.jpg") center center no-repeat;
  background-size: cover;
  height: 45vh;
  min-height: 350px;
}

/* b- Booking form */
body .booking-container {
  bottom: initial;
  top: 22vh;
}

/* ---------------------------------------------- */
/* 3) Main */
/* a- Intro */
.section-intro {
  background-color: #fff;
}

.section-title {
  padding: 3rem 0;
  position: relative;
}

.section-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.3rem;
  margin: 0 auto;
  width: 150px;
  height: 1px;
  background: #e6b664;
}

.section-subtitle {
  font-size: 1.2rem;
}

.section-description {
  color: #5f5e5e;
  text-align: center;
  padding: 2rem 0 0 0;
  width: 70%;
}

.section-rooms-gallery {
  background: #e6b663;
}

/* Container
---------------------------------------------------------------------- */

.rooms-container {
  margin: 1rem 0;
  margin-left: -3%;
  margin-right: -3.33%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.room-block {
  padding: 10px 0 5px;
  width: 100%;
}

.room-block-title {
  font-size: 20px;
  text-transform: uppercase;
  color: white;
}

/* Target Elements
---------------------------------------------------------------------- */

#almejagallery ul,
#almejagallery li {
  margin: 0;
  padding: 0;
}

.mix ul {
  margin-bottom: 0;
}

.mix li:not(.room-thumb) {
  display: none;
}

.lg-outer {
  z-index: 9999999 !important;
}

.lg-backdrop {
  z-index: 999999 !important;
}

.rooms-container .mix.almeja::before {
  content: "Almeja";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.erizo::before {
  content: "Erizo";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.cangrejo::before {
  content: "Cangrejo";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.caracola::before {
  content: "Caracola";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.pulpo::before {
  content: "Pulpo";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.medusa::before {
  content: "Medusa";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.gaviota::before {
  content: "Gaviota";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.corvina::before {
  content: "Corvina";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.rooms-container .mix.brotola::before {
  content: "Brótola";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 100%;
  font-size: 14px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.mix {
  background: #fff;
  margin-bottom: 1rem;
  margin-left: 3.33%;
  width: 30%;
  position: relative;
  min-height: 100px;
  box-sizing: border-box;
}

.mix a img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}

.mix a:hover > img {
  box-shadow: 0 1px 6px 0 rgba(60, 64, 67, 0.202),
    0 4px 8px 3px rgba(60, 64, 67, 0.1);
}

/* Grid Breakpoints
---------------------------------------------------------------------- */

/* 3 Columns */

.mix {
  width: 30%;
}

/* 2 Columns */

@media screen and (min-width: 541px) {
  .mix {
    width: 48%;
  }
}

/* 3 Columns */

@media screen and (min-width: 961px) {
  .mix {
    width: 30%;
  }
}

/* ---------------------------------------------- */
/* 5) Footer */
footer {
  background: #000 url("../../../images/rooms-bg.jpg") center center no-repeat;
  background-size: cover;
  color: #fff;
}

@media only screen and (max-width: 429px) {
  .mix {
    width: 100%;
  }

  .room-block-title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 430px) and (max-width: 900px) {
  .mix {
    width: 46.6%;
  }
}
