/*
----------- STRUCTURE -----------
1) General
2) Navbar
3) Header
4) Main
  a- Intro
  b- Rooms
  c- Services
5) Footer
*/

/* ---------------------------------------------- */
/* 1) General */
.btn-margin {
  margin: 50px 0;
  -webkit-appearance: none !important;
}
/* ---------------------------------------------- */
/* 2) Navbar */

/* ---------------------------------------------- */
/* 3) Header */

/* a- Banner */
/* b- Booking form */

/* ---------------------------------------------- */
/* 3) Main */

/* a- Intro */
.section-intro {
  background-color: #fff;
}

.section-title {
  padding: 3rem 0;
  position: relative;
}

.section-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.3rem;
  margin: 0 auto;
  width: 150px;
  height: 1px;
  background: #e6b664;
}

.section-subtitle {
  font-size: 1.2rem;
}

.section-description {
  color: #5f5e5e;
  text-align: center;
  padding: 2rem 0;
  width: 70%;
}

/* b- Rooms */
.section-rooms {
  background: #000 url("../../../images/rooms-bg.jpg") center center no-repeat;
  background-size: cover;
  color: #fff;
}

.section-rooms .section-description {
  color: #fff;
}

.rooms-gallery {
  margin: 2rem 0;
  min-height: 600px;
  height: 100vh;
  width: 100vw;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-rooms {
  height: 85%;
  width: 100%;
}

.gallery-rooms-thumbs {
  height: 15%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-rooms-thumbs .swiper-wrapper {
  left: -40vw;
}

.gallery-rooms-thumbs .swiper-slide {
  width: 20%;
  height: 100%;
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

/* c- Services */
.services-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.service-item {
  width: 150px;
  text-align: center;
}

.service-desc {
  font-size: 1.3rem;
  color: #131313;
}

.services-container [class^="services-"]:before,
.services-container [class*=" services-"]:before,
.services-container [class^="services-"]:after,
.services-container [class*=" services-"]:after {
  font-size: 100px;
  color: #3be3b8;
}

/* ---------------------------------------------- */
/* 5) Footer */

@media only screen and (max-width: 900px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .section-subtitle {
    text-align: center;
  }

  .rooms-gallery {
    margin-bottom: 0;
    min-height: auto;
    height: 300px;
  }
}
